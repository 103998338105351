<template>
  <v-dialog v-model="dialog" scrollable persistent width="1000px">
    <v-card>
      <v-card-title class="d-flex align-baseline">
        <span>{{ company.name }} </span> |
        <small class="text--secondary ml-2"> #{{ company.id }}</small>
        <div small class="ml-auto">
          <status-chip :value="company.status" class="mr-2" />
          <v-chip v-if="company.is_beta_tester" color="info" small>
            <v-icon left> mdi-bug </v-icon>Beta Tester
          </v-chip>
        </div>
      </v-card-title>

      <v-card-subtitle class="d-flex align-baseline justify-space-between">
        <span>{{ userOwner.name }}</span>

        <span>
          <WhatsAppApiBtn :phone="userOwner.phone_wa" color="primary" @click.prevent />
          {{ $format.phone(userOwner.phone_wa) }}
        </span>

      </v-card-subtitle>
      <v-card-text>
        <div class="d-flex justify-space-around">
          <v-tabs v-model="tab">
            <v-tab @click="getCompanySubscriptions()"> Assinaturas </v-tab>
            <v-tab @click="getCompanyInvoices()"> Faturas </v-tab>
            <v-tab @click="getCompanyCharges()"> Cobranças </v-tab>
          </v-tabs>
          <app-btn @click="openCompanySubscriptionForm()" color="primary">
            <v-icon left>mdi-currency-usd</v-icon>
            Nova Assinatura
          </app-btn>
        </div>
        <br />

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card outlined>
              <v-card-text>
                <app-pagination @click="getCompanySubscriptions($event)" :data="subscriptions">
                  <v-data-table hide-default-footer disable-sort :items-per-page="-1" dense :items="subscriptions.data"
                    :headers="subscriptionsHeaders" @click:row="openCompanySubscriptionForm($event.id)">

                    <template v-slot:[`item.plan`]="{ item }">
                      {{ item.plan.id }} - {{ item.plan.name }}
                    </template>

                    <template v-slot:[`item.start_at`]="{ item }">
                      {{ $format.dateBr(item.start_at) }}
                    </template>

                    <template v-slot:[`item.ends_at`]="{ item }">
                      {{ $format.dateBr(item.ends_at) }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      <SubscriptionStatusChip small :status="item.status" />
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text icon color="primary" v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item @click="paySubscription(item.id)">
                            <v-list-item-icon>
                              <v-icon> mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Confirmar Pagamento
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item v-if="item.status != 'canceled'" @click="cancelSubscription(item.id)">
                            <v-list-item-icon>
                              <v-icon> mdi-cancel</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Cancelar Assinatura
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>
                </app-pagination>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <div class="text-end py-4">
              <app-btn @click="handleNewInvoice()" color="success">Nova Fatura
              </app-btn>
            </div>
            <v-card outlined>
              <v-card-text>
                <app-pagination @click="getCompanyInvoices($event)" :data="invoices">
                  <v-data-table dense hide-default-footer disable-sort :items-per-page="-1" :items="invoices.data"
                    :headers="invoicesHeaders">
                    <template v-slot:[`item.amount`]="{ item }">
                      {{ $format.decimal(item.amount) }}
                    </template>
                    <template v-slot:[`item.paid_at`]="{ item }">
                      {{ $format.dateBr(item.paid_at) }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <SubscriptionStatusChip small :status="item.status" />

                      <v-btn small v-if="item.status == 'waiting_payment'" color="primary"
                        @click="createCharge(item.id)">
                        <v-icon left>mdi-qrcode</v-icon>
                        Gerar QR
                      </v-btn>
                    </template>
                  </v-data-table>
                </app-pagination>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card outlined>
              <v-card-text>
                <app-pagination @click="getCompanyCharges($event)" :data="charges">
                  <v-data-table dense hide-default-footer disable-sort :items="charges.data" :headers="chargesHeaders"
                    :items-per-page="-1" :item-class="getChargeClass">
                    <template v-slot:[`item.amount`]="{ item }">
                      {{ $format.decimal(item.amount) }}
                    </template>
                    <template v-slot:[`item.emitted_at`]="{ item }">
                      {{ $format.dateBr(item.emitted_at) }}
                    </template>
                    <template v-slot:[`item.due_date`]="{ item }">
                      {{ $format.dateBr(item.due_date) }}
                    </template>
                    <template v-slot:[`item.paid_at`]="{ item }">
                      <small v-if="item.is_expired" class="error--text">
                        Expirado
                      </small>

                      <span v-if="!item.is_expired">
                        {{ $format.dateBr(item.paid_at) }}
                      </span>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      <div class="d-flex flex-column">
                        <div>
                          <SubscriptionStatusChip small :status="item.status" />
                        </div>
                        <div v-if="canPayCharge(item)">
                          <v-btn small color="primary" @click="payCharge(item.pix_tx_id)">
                            <v-icon left>mdi-check</v-icon>
                            Confirmar
                          </v-btn>
                        </div>
                      </div>
                    </template>
                  </v-data-table>
                </app-pagination>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <app-back-btn @click="dialog = false" />
      </v-card-actions>
      <CompanySubscriptionForm :company="company" @store="select($event), $emit('store')"
        ref="CompanySubscriptionForm" />
    </v-card>
  </v-dialog>
</template>

<script>
import WhatsAppApiBtn from "@/components/app/ui/WhatsAppApiBtn.vue";
import SubscriptionStatusChip from "@/components/subscription/sections/SubscriptionStatusChip.vue";
import CompanySubscriptionForm from "@/components/subscription/forms/CompanySubscriptionForm.vue";

export default {
  components: {
    WhatsAppApiBtn,
    SubscriptionStatusChip,
    CompanySubscriptionForm,
  },
  data() {
    return {
      tab: null,
      dialog: false,

      company: {
        owner: {
          user: {},
        },
      },

      subscriptions: {},
      subscriptionsHeaders: [
        { text: "ID", value: "id" },
        { text: "Plano", value: "plan" },
        { text: "Início", value: "start_at", align: "center" },
        { text: "Fim", value: "ends_at", align: "center" },
        { text: "Status", value: "status", align: "center", width: "100px" },
        { text: "", value: "action", align: "center", width: "1px" }
      ],

      invoices: {},
      invoicesHeaders: [
        { text: "ID", value: "id", align: "center" },
        { text: "N°", value: "cycle", align: "center" },
        { text: "Valor", value: "amount", align: "center" },
        { text: "Pagamento", value: "paid_at", align: "center" },
        { text: "Status", value: "status", align: "center", width: "100px" },
      ],

      charges: {},
      chargesHeaders: [
        { text: "ID", value: "id", align: "center" },
        { text: "Valor", value: "amount", align: "center" },
        { text: "Emissão", value: "emitted_at", align: "center" },
        { text: "Vencimento", value: "due_date", align: "center" },
        { text: "Pagamento", value: "paid_at", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],
    };
  },

  computed: {
    userOwner() {
      return this.company.owner?.user;
    },
  },

  methods: {
    open(company_id) {
      this.$loading.start();
      this.$http
        .show("admin/companies", company_id)
        .then((response) => {
          this.company = response.company;
          this.dialog = true;
          this.select();

          this.$loading.finish();
        }).catch((error) => {
          this.$loading.finish();
        });
    },

    select() {
      this.getCompanySubscriptions();
      this.getCompanyInvoices();
      this.getCompanyCharges();
    },

    getCompanyCharges(page) {
      this.$loading.start();
      this.$http
        .index("subscription/admin/company-charges", {
          company_id: this.company.id,
          page: page,
        }).then((response) => {
          this.charges = response.charges;
          this.$loading.finish();
        }).catch((error) => this.$loading.finish());
    },

    getCompanyInvoices(page) {
      this.$loading.start();
      this.$http.index("subscription/admin/company-invoices", {
        company_id: this.company.id,
        page: page,
      }).then((response) => {
        this.invoices = response.invoices;
        this.$loading.finish();
      }).catch((error) => this.$loading.finish());
    },
    handleNewInvoice(page) {
      this.$loading.start();
      this.$http
        .store("subscription/admin/company-invoices", {
          company_id: this.company.id,
        }).then((response) => {
          this.getCompanyInvoices();
          this.$loading.finish();
        }).catch((error) => this.$loading.finish());
    },

    getCompanySubscriptions(page) {
      this.$loading.start();
      this.$http
        .index("subscription/admin/company-subscriptions", {
          company_id: this.company.id,
          page: page,
        }).then((response) => {
          this.subscriptions = response.subscriptions;
          this.$loading.finish();
        }).catch((error) => this.$loading.finish());
    },

    openCompanySubscriptionForm(subscription_id) {
      this.$refs.CompanySubscriptionForm.open(subscription_id);
    },

    getChargeClass(item) {
      return item.is_expired ? "v-data-table__selected" : "";
    },

    async createCharge() {
      this.$loading.start();
      await this.$http
        .store("subscription/efi/charge", {
          company_id: this.company.id,
        }).then((response) => {
          this.select();
          this.$loading.finish();
        }).catch((error) => this.$loading.finish());
    },

    canPayCharge(charge) {
      return (
        (charge.status == "waiting_payment" ||
          charge.status == "past_due" ||
          charge.status == "incomplete") &&
        !charge.is_expired
      );
    },

    payCharge(pix_tx_id) {
      this.$loading.start();
      this.$http
        .store("efi-pix-webhook", {
          pix: [{ txid: pix_tx_id }],
        }).then((response) => {
          this.select();
          this.$loading.finish();
        }).catch((error) => this.$loading.finish());
    },

    paySubscription(subscription_id) {
      this.$loading.start();
      this.$http
        .update("subscription/admin/pay-subscription", subscription_id)
        .then((response) => {
          this.select(this.company.id);
          this.$loading.finish();
        }).catch((error) => {
          this.$loading.finish();
        });
    },
    cancelSubscription(subscription_id) {
      this.$loading.start();
      this.$http
        .destroy("subscription/admin/manual-subscription", subscription_id)
        .then((response) => {
          this.select(this.company.id);
          this.$loading.finish();
        }).catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>
